// export default CreateMeetingOption
import {
  Affix,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
  Typography,
  TimePicker,
  Tag
} from 'antd'

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import moment from 'moment'

const { Title, Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker // Import RangePicker if needed

const formatDate = (date) => {
  return date ? date.format('YYYY-MM-DD HH:mm:ss') : ''
}

const CreateMeetingOption = ({ maxScore, assessmentType }) => {
  const [form] = Form.useForm()
  const { courseId } = useParams()
  const [allStudents, setAllStudents] = useState([])
  const [allCourses, setAllCourses] = useState([])
  const [allTeachers, setAllTeachers] = useState([])
  const [meetingDetails, setMeetingDetails] = useState({
    studentId: '',
    teacherId: '',
    courseId: '',
    topicName: '',
    meetingTime: '',
    meetingEndTime: '',
    schedule: [] // New field to hold daily schedule
  })

  const history = useHistory()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [students, teachers, courses] = await Promise.all([
          axios.get('/backend/users/getAllStudent'),
          axios.get('/backend/users/getAllTeacher'),
          axios.get('/backend/courses/getAllCoursesList')
        ])
        setAllStudents(students.data)
        setAllTeachers(teachers.data)
        setAllCourses(courses.data)
      } catch (error) {
        notification.error({
          message: 'Error fetching data',
          description: error.message
        })
      }
    }

    fetchData()
  }, [])

  const handleSubmit = async (values) => {
    try {
      await axios.post('/backend/meetings/create-meetings', {
        instructorId: meetingDetails.teacherId,
        studentId: meetingDetails.studentId,
        courseId: meetingDetails.courseId,
        start_time: meetingDetails.meetingTime,
        end_time: meetingDetails.meetingEndTime,
        toDateTime: '',
        topic: meetingDetails.topicName,
        schedule: meetingDetails.schedule // Send schedule data
      })
      notification.success({
        message: 'Meeting Created!'
      })
      history.push(`/app/course/${courseId}/schedulemeeting`)
    } catch (error) {
      notification.error({
        message: 'Error creating meeting',
        description: error.message
      })
    }
  }

  const handleAddSchedule = () => {
    setMeetingDetails((prev) => ({
      ...prev,
      schedule: [...prev.schedule, { day: '', time: '' }]
    }))
  }

  const handleChangeSchedule = (index, field, value) => {
    const updatedSchedule = [...meetingDetails.schedule]
    updatedSchedule[index] = { ...updatedSchedule[index], [field]: value }
    setMeetingDetails((prev) => ({
      ...prev,
      schedule: updatedSchedule
    }))
  }

  const handleRemoveSchedule = (index) => {
    const updatedSchedule = meetingDetails.schedule.filter(
      (_, i) => i !== index
    )
    setMeetingDetails((prev) => ({
      ...prev,
      schedule: updatedSchedule
    }))
  }

  return (
    <Affix offsetTop={10}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '10px'
        }}
      >
        <Title level={4}>Meeting Options</Title>
        <Divider />
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={meetingDetails}
        >
          <Form.Item name="topicName" label={<Text strong>Topic Name</Text>}>
            <Input.TextArea
              rows={2}
              placeholder="Lecture 1"
              onChange={(e) => {
                // console.log('e.target.value', e.target.value, meetingDetails)

                setMeetingDetails((prev) => ({
                  ...prev,
                  topicName: e.target.value
                }))
              }}
            />
          </Form.Item>

          <Form.Item name="studentId" label={<Text strong>Students</Text>}>
            <Select
              onChange={(value) =>
                setMeetingDetails((prev) => ({ ...prev, studentId: value }))
              }
              placeholder="Select a student"
            >
              {allStudents.map((student) => (
                <Option key={student._id} value={student._id}>
                  {student.email} | {student.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="teacherId" label={<Text strong>Teacher</Text>}>
            <Select
              onChange={(value) =>
                setMeetingDetails((prev) => ({ ...prev, teacherId: value }))
              }
              placeholder="Select a teacher"
            >
              {allTeachers.map((teacher) => (
                <Option key={teacher._id} value={teacher._id}>
                  {teacher.email} | {teacher.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="courseId" label={<Text strong>Courses</Text>}>
            <Select
              onChange={(value) =>
                setMeetingDetails((prev) => ({ ...prev, courseId: value }))
              }
              placeholder="Select a course"
            >
              {allCourses.map((course) => (
                <Option key={course.id} value={course.id}>
                  {course.name} | {course.description}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="meetingTime"
            label={<Text strong>Meeting From</Text>}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime
              onChange={(date) => {
                setMeetingDetails((prev) => ({
                  ...prev,
                  meetingTime: formatDate(date)
                }))
              }}
            />
          </Form.Item>

          <Form.Item
            name="meetingEndTime"
            label={<Text strong>Meeting To</Text>}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime
              onChange={(date) => {
                setMeetingDetails((prev) => ({
                  ...prev,
                  meetingEndTime: formatDate(date)
                }))
              }}
            />
          </Form.Item>

          <Form.Item label={<Text strong>Daily Meeting Schedule</Text>}>
            {meetingDetails.schedule.map((schedule, index) => (
              <Space
                key={index}
                style={{
                  display: 'flex',
                  marginBottom: '10px',
                  alignItems: 'center'
                }}
              >
                <Select
                  value={schedule.day}
                  onChange={(value) =>
                    handleChangeSchedule(index, 'day', value)
                  }
                  placeholder="Select day"
                  style={{ width: 120 }}
                >
                  <Option value="Monday">Monday</Option>
                  <Option value="Tuesday">Tuesday</Option>
                  <Option value="Wednesday">Wednesday</Option>
                  <Option value="Thursday">Thursday</Option>
                  <Option value="Friday">Friday</Option>
                  <Option value="Saturday">Saturday</Option>
                  <Option value="Sunday">Sunday</Option>
                </Select>
                <TimePicker
                  value={schedule.time ? moment(schedule.time, 'HH:mm') : null}
                  onChange={(time) =>
                    handleChangeSchedule(
                      index,
                      'time',
                      time ? time.format('HH:mm') : ''
                    )
                  }
                  format="HH:mm"
                  placeholder="Select time"
                />
                <Button
                  type="danger"
                  onClick={() => handleRemoveSchedule(index)}
                >
                  Remove
                </Button>
              </Space>
            ))}
            <Button type="dashed" onClick={handleAddSchedule}>
              Add Schedule
            </Button>
          </Form.Item>

          <Space style={{ marginTop: '8px' }}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
            <Button
              onClick={() =>
                history.push(
                  `/app/course/${courseId}/${assessmentType.toLowerCase()}s`
                )
              }
            >
              Cancel
            </Button>
          </Space>
        </Form>
      </div>
    </Affix>
  )
}

export default CreateMeetingOption
