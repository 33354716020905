import axios from 'axios'
import { getAuthHeader } from './config'
import { addParticipants } from '../reducers/participantsReducer'

const baseURL = '/enrollments'

const getEnrollments = async (courseId) => {
  const response = await axios.get(
    `/backend/${courseId}${baseURL}`,
    getAuthHeader()
  )

  return response.data
}

const updateEnrollment = async (courseId, enrollmentId, enrolledAs) => {
  const response = await axios.post(
    `/backend/${courseId}${baseURL}`,
    {
      enrollmentId,
      enrolledAs
    },
    getAuthHeader()
  )
  return response.data
}
const addParticipant = async (courseId, selectedParticipant) => {
  const response = await axios.post(
    `/backend/courses/${courseId}/enroll`,
    {
      courseId,
      userId: selectedParticipant[0]._id
    },
    getAuthHeader()
  )
  return response.data
}

const removeParticipant = async (courseId, userId) => {
  const response = await axios.post(
    `/backend/courses/${courseId}/un-enroll`,
    {
      userId
    },
    getAuthHeader()
  )
  return response.data
}

const enrollmentsService = {
  getEnrollments,
  updateEnrollment,
  addParticipant,
  removeParticipant
}
export default enrollmentsService
