import axios from 'axios'
import { getAuthHeader } from './config'

const baseURL = '/assessments'

const getAllExams = async (courseId, privilege, userId) => {
  let params = {
    filter: 'Exam',
    type:
      privilege === 'instructor'
        ? 'instructor'
        : privilege === 'STUDENT'
        ? 'student'
        : 'instructor'
  }
  if (privilege === 'student') {
    params.studentId = userId
  }
  const response = await axios.get(`/backend/${courseId}${baseURL}`, {
    ...getAuthHeader(),
    params: params
  })
  return response.data
}

const getAllAssignments = async (courseId, privilege, userId) => {
  let params = {
    filter: 'Assignment',
    type:
      privilege === 'instructor'
        ? 'instructor'
        : privilege === 'STUDENT'
        ? 'student'
        : 'instructor'
  }
  if (privilege === 'student') {
    params.studentId = userId
  }
  const response = await axios.get(`/backend/${courseId}${baseURL}`, {
    ...getAuthHeader(),
    params: params
  })
  return response.data
}

const submitAssessment = async (courseId, assessment) => {
  const response = await axios.post(
    `/backend/${courseId}${baseURL}`,
    assessment,
    getAuthHeader()
  )
  return response.data
}

const deleteAssessment = async (courseId, assessmentId) => {
  const response = await axios.delete(
    `/backend/${courseId}${baseURL}/${assessmentId}`,
    getAuthHeader()
  )
  return response.data
}

const assessmentsService = {
  getAllExams,
  getAllAssignments,
  submitAssessment,
  deleteAssessment
}
export default assessmentsService
