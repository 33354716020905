import { Col, Row } from 'antd'

import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  clear
} from '../../../reducers/assessmentCreationReducer'

import { useHistory, useParams } from 'react-router'

import CreateMeetingOption from './createMeetingOption'

const CreateMeeting = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { courseId } = useParams()

  const assessmentType = props.assessmentType

  const questions = useSelector((state) => state.assessmentCreation.questions)
  const [title, setTitle] = useState('Assessment Title')

  const [controlledQuestionType, setControlledQuestionType] = useState('')

  const [files, setFiles] = useState([])

  useEffect(() => {
    return () => {
      dispatch(clear())
    }
  }, [dispatch])

  const handlecontrolledQuestionType = (value) => {
    setControlledQuestionType(value)
  }


  const getMaxScore = () => {
    return questions.reduce((acumalator, item) => {
      if (item.points) return acumalator + item.points
      return acumalator
    }, 0)
  }

  return (
    <Row gutter={[16, 16]}>
      {/* <Col span={18}>
        <MeetingList
          controlledQuestionType={controlledQuestionType}
          files={files}
          setFiles={setFiles}
          questions={questions}
          title={title}
          setTitle={setTitle}
        />
      </Col> */}

      <Col span={18}>
        <CreateMeetingOption
          controlledQuestionType={controlledQuestionType}
          handlecontrolledQuestionType={handlecontrolledQuestionType}
          assessmentType={assessmentType}
          maxScore={getMaxScore()}
        />
      </Col>
    </Row>
  )
}

export default CreateMeeting
